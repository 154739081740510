import './HomePage.scss';
import Hero from "../../Components/Hero/Hero";
import Footer from '../../Components/Footer/Footer';
import Projects from '../../Components/Projects/Projects';
import Languages from '../../Components/Languages/Languages';


function HomePage() {
    return (
        <section className="homepage">
            <Hero />
            <Projects />
            <Languages />
            <Footer />
        </section>
    );
}

export default HomePage;
