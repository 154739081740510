import Capstone from "../Capstone/Capstone";
import Instock from "../Instock/Instock";
import Ukg from "../Ukg/Ukg";
import "./Projects.scss";

function Projects() {
    return (
        <section className="projects" id="projects">
            <h2 className="projects__header">PROJECTS</h2>
            <div className="projects__container">
                <Capstone className="projects__card" />
                <Ukg className="projects__card" />
                <Instock className="projects__card" />
            </div>
        </section>
    );
}

export default Projects;
