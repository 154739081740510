import "./AboutPage.scss";
import { Link } from "react-router-dom";

function AboutPage() {
    return (
        <section className="aboutpage">
            <div className="aboutpage__container-about">
                <h1 className="aboutpage__about-header">About Me</h1>
                <p className="aboutpage__about-content">
                    Hey there, I'm Armando 'Mandy' Fernandez! If you're
                    searching for a software engineer who loves diving into code
                    just as much as they love a good cup of coffee, then you're
                    in the right place. I'm all about turning complex problems
                    into simple solutions and building software that doesn't
                    just work, but changes the game.
                </p>

                <p className="aboutpage__about-content">
                    I started my journey as a financial advisor. Yeah, I was
                    deep in the world of numbers, crunching data, solving
                    complex problems, and making sense of it all for my clients.
                    I was pretty good at it too. But, you know what really lit
                    my spark? Technology. I saw how software was changing the
                    game in finance, and thought, "Wow, I want to be part of
                    that."
                </p>

                <p className="aboutpage__about-content">
                    So, I took a leap of faith and switched lanes to become a
                    software engineer. Sounds crazy, I know. But hey, it was one
                    of the best decisions I ever made. Why? Because I love what
                    I do. I love diving into intricate lines of code, working
                    with awesome teams, and pushing myself to the limit to
                    deliver amazing results.
                </p>

                <p className="aboutpage__about-content">
                    Now, I get to use my financial savvy to make even cooler
                    software. And the best part? I know my stuff really makes a
                    difference. Whether it's creating an innovative finance app
                    or developing a system that transforms how a business
                    operates, I get a kick out of it.
                </p>

                <p className="aboutpage__about-content">
                    Today, I'm proud to be a software engineer, driven not just
                    by a passion for tech, but by a deep understanding of how it
                    can drive innovation and transformation. My unique blend of
                    financial expertise and technical skills allows me to bring
                    a fresh perspective to software projects, focusing not only
                    on the technology but on the end-user and their unique
                    needs.
                </p>

                <p className="aboutpage__about-content">
                    So, if you're on the lookout for a software engineer with a
                    unique blend of financial know-how and tech skills, look no
                    further! I can't wait to chat about how we can build amazing
                    things together. Let's create something incredible, shall
                    we?
                </p>
            </div>
            <div className="aboutpage__container-button">
                <Link
                    to="https://drive.google.com/file/d/1jiW4A5f6-PC8sH60dkyFTaSAJxobtzDb/view?usp=sharing"
                    target="_blank"
                >
                    <button className="aboutpage__button">RESUMÉ</button>
                </Link>
            </div>
        </section>
    );
}

export default AboutPage;
