import "./Hero.scss";
import headshot from "../../Assets/Images/mandy-transparent.png";

function Hero() {

    return (
        <section className="hero">
            
            <div className="hero__container-content">
                <h1 className="hero__header">
                    Hi, I'm <span className="hero__header-span">MANDY</span>!
                </h1>
                <h4 className="hero__subheader">
                    Passionate Full-Stack Engineer Bringing Web Solutions to
                    Life with Boundless Excitement
                </h4>
            </div>
            <div className="hero__container-image">
                <img
                    src={headshot}
                    alt="professional headshot of Armando Fernandez"
                    className="hero__image"
                />
            </div>
        </section>
    );
}

export default Hero;
