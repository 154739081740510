import "./Ukg.scss";
import Carousel from "react-bootstrap/Carousel";
import desktop from "../../Assets/Images/ukg/ukg-desktop.png"
import chatbot from "../../Assets/Images/ukg/ukg-chatbot.png"
import dashboard from "../../Assets/Images/ukg/ukg-dashboard.png"
import { Link } from "react-router-dom";


function Ukg() {
    return (
        <section className="ukg">
            <Link 
            to='https://github.com/ArmandoMandyFernandez/UKG-Industry-Project' 
            className="ukg__container"
            target="_blank">
                <Carousel fade className="ukg__carousel">
                    <Carousel.Item className="ukg__carousel-item">
                        <img
                            className="d-block w-100 ukg__carousel-image"
                            src={dashboard}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="ukg__carousel-item">
                        <img
                            className="d-block w-100 ukg__carousel-image"
                            src={chatbot}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="ukg__carousel-item">
                        <img
                            className="d-block w-100 ukg__carousel-image"
                            src={desktop}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <div className="ukg__content">
                    <h2 className="ukg__content-title">UKG - Ceci</h2>
                    <h3 className="ukg__content-description">
                    This project is a dashboard developed for UKG that integrates a scoring system for employees to earn rewards based on their status, along with a mood section featuring an OpenAI-powered chat bot to assist employees with managing their emotions. Managers also receive a mood board report to track the moods of their team members.
                    </h3>
                    <h5 className="ukg__content-languages"> <span className="ukg__content-languages-span">Front-End:</span>  React, Sass, Javascript, Framer Motion, Chatscope, Axios</h5>
                    <h5 className="ukg__content-languages"> <span className="ukg__content-languages-span">Back-end:</span>  Node.js</h5>
                </div>
            </Link>
        </section>
    );
}

export default Ukg;
