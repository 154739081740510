import "./App.scss";
import NavBar from "./Components/NavBar/NavBar";
import AboutPage from "./Pages/AboutPage/AboutPage";
import HomePage from "./Pages/HomePage/HomePage";
import { Routes, Route } from "react-router-dom";

function App() {
    return (
        <div className="App">
            <NavBar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="about" element={<AboutPage />} />
            </Routes>
        </div>
    );
}

export default App;
