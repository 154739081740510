import "./Footer.scss";
import github from "../../Assets/Icons/github.png";
import linkedin from "../../Assets/Icons/linkedin.png";
import email from '../../Assets/Icons/email.png'
import { Link } from "react-router-dom";

function Footer() {
    return (
        <section className="footer">
            <h6 className="footer__subheader">Feel Free to Contact Me</h6>
            <div className="footer__container-social">
                <Link to="https://github.com/ArmandoMandyFernandez"
                target="_blank">
                    <img 
                    src={github} 
                    alt="github icon" 
                    className="footer__social-git" />
                </Link>
                <Link to="https://www.linkedin.com/in/armando-mandy-fernandez/"
                target="_blank">
                    <img 
                    src={linkedin} 
                    alt="linkedin icon" 
                    
                    className="footer__social" />
                </Link>
                <Link to="#" onClick={(e) => {window.location.href ='mailto:mfernandezjr10@gmail.com';}}>
                    <img src={email} alt="email icon" className="footer__social" />
                </Link>
            </div>
        </section>
    );
}

export default Footer;
