import "./Languages.scss";
import html from "../../Assets/Images/languages/html-css-js-logo.png";
import react from "../../Assets/Images/languages/react-logo.png";
import bootstrap from "../../Assets/Images/languages/bootstrap-logo.png";
import bulma from "../../Assets/Images/languages/bulma-logo.png";
import node from "../../Assets/Images/languages/node-js-logo.png";
import mysql from "../../Assets/Images/languages/mysql-logo.png";
import sass from "../../Assets/Images/languages/sass-logo.png";
import express from "../../Assets/Images/languages/express-logo.png";
import firebase from "../../Assets/Images/languages/firebase-logo.png";
import knex from "../../Assets/Images/languages/knex-logo.png";

function Languages() {
    return (
        <section className="languages">
            <h2 className="languages__header">Languages & Frameworks</h2>
            <div className="languages__container-images">
                <div className="languages__container-images-section">
                    <img
                        src={html}
                        alt="html css and js logo"
                        className="languages__image"
                    />
                    <img
                        src={react}
                        alt="react logo"
                        className="languages__image"
                    />
                    <img
                        src={sass}
                        alt="sass logo"
                        className="languages__image"
                    />
                </div>
                <div className="languages__container-images-section">
                    <img
                        src={bootstrap}
                        alt="bootstrap logo"
                        className="languages__image"
                    />
                    <img
                        src={bulma}
                        alt="bulma logo"
                        className="languages__image bulma"
                    />
                </div>
                <div className="languages__container-images-section">
                    <img
                        src={node}
                        alt="node logo"
                        className="languages__image"
                    />
                    <img
                        src={mysql}
                        alt="mysql logo"
                        className="languages__image mysql"
                    />
                </div>
                <div className="languages__container-images-section">
                    <img
                        src={express}
                        alt="express logo"
                        className="languages__image express"
                    />
                    <img
                        src={knex}
                        alt="knex logo"
                        className="languages__image knex"
                    />
                    <img
                        src={firebase}
                        alt="firebase logo"
                        className="languages__image firebase"
                    />
                </div>
            </div>
        </section>
    );
}

export default Languages;
