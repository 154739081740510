import "./Instock.scss";
import Carousel from "react-bootstrap/Carousel";
import warehouse from "../../Assets/Images/instock/warehouses.png"
import inventory from "../../Assets/Images/instock/Inventory.png"
import editInventory from "../../Assets/Images/instock/edit-inventory.png"
import editWarehouse from "../../Assets/Images/instock/edit-warehouse.png"
import { Link } from "react-router-dom";



function Instock() {
    return (
        <section className="instock">
            <Link to="https://github.com/luke-mar/instock-ed1-front-end" className="instock__container"
            target="_blank">
                <Carousel fade className="instock__carousel">
                    <Carousel.Item className="instock__carousel-item">
                        <img
                            className="d-block w-100 instock__carousel-image"
                            src={warehouse}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="instock__carousel-item">
                        <img
                            className="d-block w-100 instock__carousel-image"
                            src={editWarehouse}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="instock__carousel-item">
                        <img
                            className="d-block w-100 instock__carousel-image"
                            src={inventory}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="instock__carousel-item">
                        <img
                            className="d-block w-100 instock__carousel-image"
                            src={editInventory}
                            alt="Fourth slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <div className="instock__content">
                    <h2 className="instock__content-title">Instock</h2>
                    <h3 className="instock__content-description">
                    InStock is a warehouse inventory project that facilitated CRUD operations for managing warehouses and inventory items. Users could create, read, update, and delete warehouses and associated inventory entries, providing a comprehensive solution for efficient inventory management.
                    </h3>
                    <h5 className="instock__content-languages"> <span className="instock__content-languages-span">Front-End:</span>  React, Sass, Axios</h5>
                    <h5 className="instock__content-languages"> <span className="instock__content-languages-span">Back-end:</span>Node.js, MySQL, Express.js, Knex.js </h5>
                </div>
            </Link>
        </section>
    );
}

export default Instock;
