import "./NavBar.scss";
import logo from "../../Assets/Images/mandy-logo-no-background.png";
import hamburger from '../../Assets/Icons/menu.png';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useState } from "react";

function NavBar() {
    const [open, setOpen] = useState(false);

    return (
        <nav className="navbar">
            <Link to="/" className="navbar__logo" ><img src={logo} alt="mandy logo" className="navbar__logo" /></Link>
            
            <div className="navbar__container">
                <div className="navbar__container-icon">
                    <img
                        src={hamburger}
                        alt="hamburger icon"
                        className="navbar__mobile-menu-icon"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    />
                </div>
                <div
                    className={`navbar__container-links ${
                        open ? "active" : "inactive"
                    }`}
                >
                    <ul className="navbar__links">
                        <li className="navbar__link">
                            <ScrollLink
                                to="projects"
                                smooth={true}
                                offset={-20}
                                duration={50}
                                className="navbar__link"
                            >
                                PROJECTS
                            </ScrollLink>
                        </li>
                        <li className="navbar__link">
                            <Link to="about" className="navbar__link">ABOUT</Link>
                        </li>
                        {/* <li className="navbar__link">
                            <Link className="navbar__link">CONTACT</Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
