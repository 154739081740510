import "./Capstone.scss";
import Carousel from "react-bootstrap/Carousel";
import ymcaHomepage from "../../Assets/Images/capstone/ymca-homepage.png";
import ymcaDashboard from "../../Assets/Images/capstone/ymca-dashboard.png";
import ymcaBudget from "../../Assets/Images/capstone/ymca-dashboard-budget.png";
import { Link } from "react-router-dom";

function Capstone() {
    return (
        <section className="capstone">
            <Link 
            to='https://yourmoneycountsalso.com'
            className="capstone__container"
            target="_blank">
                <Carousel fade className="capstone__carousel">
                    <Carousel.Item className="capstone__carousel-item">
                        <img
                            className="d-block w-100 capstone__carousel-image"
                            src={ymcaHomepage}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="capstone__carousel-item">
                        <img
                            className="d-block w-100 capstone__carousel-image"
                            src={ymcaDashboard}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="capstone__carousel-item">
                        <img
                            className="d-block w-100 capstone__carousel-image"
                            src={ymcaBudget}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <div className="capstone__content">
                    <h2 className="capstone__content-title">Your Money Counts Also</h2>
                    <h3 className="capstone__content-description">
                        An intuitive website designed to empower you, to put you
                        back in the driver's seat of your financial life.
                        Keeping track of your income and expenses has never been
                        this straightforward.
                    </h3>
                    <h5 className="capstone__content-languages"> <span className="capstone__content-languages-span">Front-End:</span>  React, Sass, Javascript, Bootstrap</h5>
                    <h5 className="capstone__content-languages"> <span className="capstone__content-languages-span">Back-end:</span>  Node.js, Firebase</h5>
                </div>
            </Link>
        </section>
    );
}

export default Capstone;
